"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = checkPermission;

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var _store = _interopRequireDefault(require("@/store"));

/**
 * @copyright chuzhixin 1204505056@qq.com
 * @description 检查权限
 * @param value
 * @returns {boolean}
 */
function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    var permissions = _store.default.getters["user/permissions"];
    var permissionPermissions = value;
    return permissions.some(function (role) {
      return permissionPermissions.includes(role);
    });
  } else {
    return false;
  }
}