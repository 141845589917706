"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var _store = _interopRequireDefault(require("@/store"));

var _default = {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var permissions = _store.default.getters["user/permissions"];

    if (value && value instanceof Array && value.length > 0) {
      var hasPermission = permissions.some(function (role) {
        return value.includes(role);
      });

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    }
  }
};
exports.default = _default;