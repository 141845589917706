var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.query, inline: true },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入运输单号" },
                    model: {
                      value: _vm.query.trackNo,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "trackNo", $$v)
                      },
                      expression: "query.trackNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "start-placeholder": "签收开始日期",
                      "end-placeholder": "签收结束日期"
                    },
                    on: { change: _vm.timeFormat },
                    model: {
                      value: _vm.query.DATE,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "DATE", $$v)
                      },
                      expression: "query.DATE"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        "native-type": "submit"
                      },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "tableSort",
          attrs: {
            data: _vm.tableData,
            "element-loading-text": _vm.elementLoadingText
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "所属部门", prop: "deptement", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "batch_order", label: "波次号" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "batch_order_st",
              label: "波次开始时间",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "out_type", label: "出库类型" }
          }),
          _c("el-table-column", {
            attrs: { prop: "batch_status", label: "波次状态", width: "110" }
          }),
          _c("el-table-column", {
            attrs: { label: "创建人", prop: "create_user" }
          }),
          _c("el-table-column", {
            attrs: { label: "送货地址", prop: "send_address" }
          }),
          _c("el-table-column", {
            attrs: { label: "仓库编号", prop: "warehouse_no", width: "100" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "波次编号",
              prop: "batch_no",
              width: "100",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: { label: "波次行号", prop: "batch_row" }
          }),
          _c("el-table-column", {
            attrs: { label: "物料编码", prop: "mat_code", width: "110" }
          }),
          _c("el-table-column", {
            attrs: { label: "波次数量", prop: "batch_num" }
          }),
          _c("el-table-column", {
            attrs: { label: "明细状态", prop: "mat_detail_status" }
          }),
          _c("el-table-column", {
            attrs: { label: "单位", prop: "mat_units" }
          }),
          _c("el-table-column", {
            attrs: { label: "源单编码", prop: "src_code" }
          }),
          _c("el-table-column", {
            attrs: { label: "明细状态", prop: "mat_detail_status" }
          }),
          _c("el-table-column", {
            attrs: { label: "单位", prop: "mat_units" }
          }),
          _c("el-table-column", {
            attrs: { label: "源单分录", prop: "src_code" }
          }),
          _c("el-table-column", {
            attrs: { label: "源单分录", prop: "src_subjects" }
          }),
          _c("el-table-column", {
            attrs: { label: "车次号", prop: "cartime_no" }
          }),
          _c("el-table-column", { attrs: { label: "车号", prop: "car_no" } }),
          _c("el-table-column", {
            attrs: { label: "出库月台", prop: "outbound_platform" }
          }),
          _c("el-table-column", {
            attrs: { label: "承运人", prop: "carrier" }
          }),
          _c("el-table-column", {
            attrs: { label: "联系方式", prop: "telphone" }
          }),
          _c("el-table-column", {
            attrs: { label: "需求时间", prop: "exp_time" }
          }),
          _c("el-table-column", {
            attrs: { label: "车辆到厂时间", prop: "car_totime" }
          }),
          _c("el-table-column", {
            attrs: { label: "装车完成时间", prop: "fin_cartime" }
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: _vm.background,
          "current-page": _vm.query.PAGE,
          layout: _vm.layout,
          "page-size": _vm.query.SIZE,
          total: _vm.total
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }