"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getYYtimeUse = getYYtimeUse;
exports.getYYtimeList = getYYtimeList;
exports.saveYYtimeUse = saveYYtimeUse;
exports.genDayYYtime = genDayYYtime;
exports.getFactoryListRef = getFactoryListRef;
exports.getFactoryList = getFactoryList;
exports.dofactorylist = dofactorylist;
exports.getSuppilerNamebybillcode = getSuppilerNamebybillcode;
exports.getListCheck = getListCheck;
exports.getListReceipt = getListReceipt;
exports.doEdit = doEdit;
exports.doAdd = doAdd;
exports.doCheck = doCheck;
exports.doReceive = doReceive;
exports.doDelete = doDelete;
exports.doExpressInfo = doExpressInfo;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: "/pda/getYYtimeDefine",
    method: "post",
    data: data
  });
}

function getYYtimeUse(data) {
  return (0, _request.default)({
    url: "/pda/getYYtimeUse",
    method: "post",
    data: data
  });
}

function getYYtimeList(data) {
  return (0, _request.default)({
    url: "/pda/getYYtimeList",
    method: "post",
    data: data
  });
}

function saveYYtimeUse(data) {
  return (0, _request.default)({
    url: "/pda/saveYYtimeApp",
    method: "post",
    data: data
  });
}

function genDayYYtime(data) {
  return (0, _request.default)({
    url: "/pda/genDayYYtime",
    method: "post",
    data: data
  });
} //  获取厂区资料参照 2022.04.03


function getFactoryListRef(data) {
  return (0, _request.default)({
    url: "/pda/getFactoryListRef",
    method: "post",
    data: data
  });
} // getFactoryList 获取厂区资料 2022.04.03


function getFactoryList(data) {
  return (0, _request.default)({
    url: "/pda/getFactoryList",
    method: "post",
    data: data
  });
} // getFactoryList 获取厂区资料 2022.04.03


function dofactorylist(data) {
  return (0, _request.default)({
    url: "/pda/dofactorylist",
    method: "post",
    data: data
  });
} //获取获取供应商名称


function getSuppilerNamebybillcode(data) {
  return (0, _request.default)({
    url: "/pda/getSuppilerNamebybillcode",
    method: "post",
    data: data
  });
}

function getListCheck(data) {
  return (0, _request.default)({
    url: "/pda/getShipmentCheckList",
    method: "post",
    data: data
  });
}

function getListReceipt(data) {
  return (0, _request.default)({
    url: "/pda/getShipmentReceiptList",
    method: "post",
    data: data
  });
}

function doEdit(data) {
  return (0, _request.default)({
    url: "/pda/editSaveYYtimeDefine",
    method: "post",
    data: data
  });
} //新增和修改时段


function doAdd(data) {
  return (0, _request.default)({
    url: "/pda/editYYtimeDefine",
    method: "post",
    data: data
  });
}

function doCheck(data) {
  return (0, _request.default)({
    url: "/pda/checkShipmentByCode",
    method: "post",
    data: data
  });
}

function doReceive(data) {
  return (0, _request.default)({
    url: "/pda/receiveShipmentByCode",
    method: "post",
    data: data
  });
} //删除预约时段


function doDelete(data) {
  return (0, _request.default)({
    url: "/pda/deleteYYtimeDefine",
    method: "post",
    data: data
  });
}

function doExpressInfo() {
  return (0, _request.default)({
    url: "/pda/getExpressInfo",
    method: "post"
  });
}