var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "vab-query-form",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.query, inline: true },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间"
                        },
                        on: { change: _vm.timeFormat },
                        model: {
                          value: _vm.query.DATE,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "DATE", $$v)
                          },
                          expression: "query.DATE"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-plus-outline",
                            type: "primary",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handleSearch }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-edit",
                            type: "warning",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handleAppoint }
                        },
                        [_vm._v("指定承运商")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-check",
                            type: "danger",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handlepClose }
                        },
                        [_vm._v("关闭")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "tableSort",
              attrs: {
                data: _vm.tableData,
                "element-loading-text": _vm.elementLoadingText
              },
              on: {
                "expand-change": _vm.rowExpand,
                "selection-change": _vm.setSelectRows
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand", prop: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-table",
                          { attrs: { data: scope.row.ruleItemData } },
                          [
                            _c("el-table-column", {
                              attrs: { label: "序号", prop: "rowno" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "物料编码", prop: "matCode" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "规格", prop: "matSpec" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "物料名称", prop: "matName" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "件数", prop: "fnum" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "主数量", prop: "znum" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "主单位", prop: "unit" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "体积", prop: "discount" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "(收货)联系人",
                                prop: "receiveContrans"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "(收货)联系电话",
                                prop: "receivePhone"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "客户编码", prop: "customCode" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收货地址",
                                prop: "receiveAddress"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "备注", prop: "memo" }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _vm.show
                ? _c("el-table-column", {
                    attrs: { label: "主键", prop: "pkTmsorder", width: "0" }
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { prop: "orgName", label: "物流组织", width: "130" }
              }),
              _c("el-table-column", {
                attrs: { label: "发货单号", prop: "tmsOrder", width: "130" }
              }),
              _c("el-table-column", {
                attrs: { label: "发货日期", prop: "sendData", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { label: "到货日期", prop: "arrivedData", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { label: "发货仓库", prop: "sendHouse" }
              }),
              _c("el-table-column", {
                attrs: { label: "销售部门", prop: "saleDepartment" }
              }),
              _c("el-table-column", {
                attrs: { label: "运输方式", prop: "trans_type" }
              }),
              _c("el-table-column", {
                attrs: { label: "订单编号", prop: "orderNo", width: "130" }
              }),
              _c("el-table-column", {
                attrs: { label: "订单类型", prop: "orderType" }
              }),
              _c("el-table-column", {
                attrs: { label: "发货类型", prop: "send_type" }
              }),
              _c("el-table-column", {
                attrs: { label: "单据状态", prop: "orderStatus" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.orderstatus, function(item) {
                        return _c("div", [
                          scope.row.orderStatus == item.value
                            ? _c("span", [_vm._v(_vm._s(item.label))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "制单人", prop: "maker" }
              }),
              _c("el-table-column", {
                attrs: { label: "单据日期", prop: "makedata", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { label: "审核日期", prop: "makedata", width: "100" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "memo",
                  label: "物流备注",
                  width: "200",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "250",
                  align: "center",
                  fixed: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-thumb" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("指定承运商")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-edit" },
                            on: {
                              click: function($event) {
                                return _vm.handleAduit(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("审核")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-delete" },
                            on: {
                              click: function($event) {
                                return _vm.handleClose(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("关闭")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: _vm.background,
              "current-page": _vm.query.PAGE,
              layout: _vm.layout,
              "page-size": _vm.query.SIZE,
              "page-sizes": _vm.pagesizes,
              total: _vm.total
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialog_title,
            visible: _vm.editVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "120px"
              }
            },
            [
              false
                ? _c(
                    "el-form-item",
                    { attrs: { label: "主键", prop: "pkTmsorder" } },
                    [
                      false
                        ? _c("el-input", {
                            attrs: { disabled: _vm.isEdit },
                            model: {
                              value: _vm.form.pkTmsorder,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "pkTmsorder", $$v)
                              },
                              expression: "form.pkTmsorder"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "发货单号", prop: "tmsOrder" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: _vm.form.tmsOrder,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tmsOrder", $$v)
                      },
                      expression: "form.tmsOrder"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "承运商", prop: "shipper" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.shipper,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "shipper", $$v)
                        },
                        expression: "form.shipper"
                      }
                    },
                    _vm._l(_vm.expressList, function(item) {
                      return _c("el-option", {
                        key: item.expressCode,
                        attrs: {
                          label: item.expressName,
                          value: item.expressCode
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预计提货时间", prop: "needTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "预计提货时间",
                      align: "right",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.form.needTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "needTime", $$v)
                      },
                      expression: "form.needTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "tmemo" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.tmemo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tmemo", $$v)
                      },
                      expression: "form.tmemo"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }