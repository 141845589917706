var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.query, inline: true },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        "native-type": "submit"
                      },
                      on: { click: _vm.handleAdd }
                    },
                    [_vm._v("新增厂区")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        type: "warning",
                        "native-type": "submit"
                      },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("刷新")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "tableSort",
          attrs: {
            data: _vm.tableData,
            "element-loading-text": _vm.elementLoadingText
          },
          on: {
            "selection-change": _vm.setSelectRows,
            "sort-change": _vm.tableSortChange
          }
        },
        [
          _vm.show
            ? _c("el-table-column", {
                attrs: { prop: "id", label: "序号", width: "70" }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { prop: "factoryno", label: "厂区编号", width: "70" }
          }),
          _c("el-table-column", {
            attrs: { prop: "factoryname", label: "厂区名称", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { prop: "vmemo", label: "备注", width: "150" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "operation",
              label: "操作",
              width: "200",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "el-icon-position" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope.row)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "red",
                        attrs: { type: "text", icon: "el-icon-error" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: _vm.background,
          "current-page": _vm.query.PAGE,
          layout: _vm.layout,
          "page-size": _vm.query.SIZE,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialog_title,
            visible: _vm.editVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "100px"
              }
            },
            [
              _vm.show
                ? _c(
                    "el-form-item",
                    { attrs: { label: "id", prop: "id" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "id", $$v)
                          },
                          expression: "form.id"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "厂区编码", prop: "factoryno" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.factoryno,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "factoryno", $$v)
                      },
                      expression: "form.factoryno"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "厂区名称", prop: "factoryname" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.factoryname,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "factoryname", $$v)
                      },
                      expression: "form.factoryname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "vmemo" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.vmemo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "vmemo", $$v)
                      },
                      expression: "form.vmemo"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }