var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.query, inline: true },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "预约日期" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.pickerOptions,
                      placeholder: "请选择预约日期",
                      width: "300"
                    },
                    on: { change: _vm.timeFormat },
                    model: {
                      value: _vm.query.DATE,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "DATE", $$v)
                      },
                      expression: "query.DATE"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择厂区" } },
                [
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择厂区", width: "300" },
                        model: {
                          value: _vm.query.factoryvalue,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "factoryvalue", $$v)
                          },
                          expression: "query.factoryvalue"
                        }
                      },
                      _vm._l(_vm.options, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ]
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预约类型" } },
                [
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "预约类型", width: "300" },
                        model: {
                          value: _vm.query.optype,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "optype", $$v)
                          },
                          expression: "query.optype"
                        }
                      },
                      _vm._l(_vm.yytype, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ]
                ],
                2
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        "native-type": "submit"
                      },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "tableSort",
          attrs: {
            data: _vm.tableData,
            "element-loading-text": _vm.elementLoadingText
          },
          on: {
            "selection-change": _vm.setSelectRows,
            "sort-change": _vm.tableSortChange
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "pkCarapp", label: "序号", width: "70" }
          }),
          _c("el-table-column", {
            attrs: { prop: "factory", label: "厂区", width: "70" }
          }),
          _c("el-table-column", {
            attrs: { prop: "yytype", label: "预约类型", width: "70" }
          }),
          _c("el-table-column", {
            attrs: { prop: "carorderno", label: "车次号", width: "70" }
          }),
          _c("el-table-column", {
            attrs: { prop: "yydate", label: "预约日期", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "yytime", label: "预约时间", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "yystatus", label: "到达时间", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "yymemo", label: "车型", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "yymemo", label: "总重量", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "shipper", label: "承运商", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "platform", label: "月台", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "ts", label: "创建时间", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { prop: "creater", label: "提交人", width: "100" }
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: _vm.background,
          "current-page": _vm.query.PAGE,
          layout: _vm.layout,
          "page-size": _vm.query.SIZE,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialog_title,
            visible: _vm.editVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "承运商" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.numberno,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "numberno", $$v)
                      },
                      expression: "form.numberno"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "厂区" } },
                [
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择厂区", width: "300" },
                        model: {
                          value: _vm.form.factory,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "factory", $$v)
                          },
                          expression: "form.factory"
                        }
                      },
                      _vm._l(_vm.options, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ]
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预约时段" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.yytime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "yytime", $$v)
                      },
                      expression: "form.yytime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车次号" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.yycount,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "yycount", $$v)
                      },
                      expression: "form.yycount"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialog_title,
            visible: _vm.editVisibleSH,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editVisibleSH = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "采购到货单号" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.orderno,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "orderno", $$v)
                      },
                      expression: "form.orderno"
                    }
                  }),
                  _c("el-button", [_vm._v("获取采购到货单信息")])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "厂区" } },
                [
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择厂区", width: "300" },
                        model: {
                          value: _vm.form.factory,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "factory", $$v)
                          },
                          expression: "form.factory"
                        }
                      },
                      _vm._l(_vm.options, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ]
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "供应商名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.yytime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "yytime", $$v)
                      },
                      expression: "form.yytime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.yycount,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "yycount", $$v)
                      },
                      expression: "form.yycount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.yycount,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "yycount", $$v)
                      },
                      expression: "form.yycount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车牌号" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.yycount,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "yycount", $$v)
                      },
                      expression: "form.yycount"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisibleSH = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }